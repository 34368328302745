import { template as template_131806f8f4b14cfcb367eef5cab514f3 } from "@ember/template-compiler";
const FKText = template_131806f8f4b14cfcb367eef5cab514f3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
