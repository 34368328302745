import { template as template_78a47d4299e64b849517288ccf9217e0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_78a47d4299e64b849517288ccf9217e0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
